<template>
  <div style="margin-top: 150px" class="text-center text-muted mb-5">
    <h2 style="font-weight: 100 !important">404 | PAGE NOT FOUND</h2>
  </div>
  <div class="text-center text-muted">
    <router-link :to="{ name: 'dashboard' }">Go to Dashboard</router-link>
  </div>
</template>


